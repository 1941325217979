import React from "react";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <section className="grid grid-cols-6 gap-y-28 mx-5 xl:mx-auto xl:w-[1078px]">
      <div className="col-start-2 col-span-2 xl:col-start-1">
        <p className="mp font-bold">Support</p>
      </div>
      <div className="col-span-3">
        <Link to="/pricing" className="text-2xl mx-0 hover:text-gray-500">Pricing</Link><br />
        <Link to="/docs" className="text-2xl mx-0 hover:text-gray-500">Documentation</Link>
      </div>
      <div className="col-start-2 col-span-2 xl:col-start-1">
        <p className="mp font-bold">Company</p>
      </div>
      <div className="col-span-3">
        <Link to="/about" className="text-2xl mx-0 hover:text-gray-500">About</Link>
      </div>
      <div className="col-start-2 col-span-2 xl:col-start-1">
        <p className="mp font-bold">Legal</p>
      </div>
      <div className="col-span-3">
        {/*<Link to="/privacy" className="text-2xl mx-0 hover:text-gray-500">Privacy</Link><br />*/}
        <a href="https://www.iubenda.com/privacy-policy/66998340" target="_blank" className="text-2xl mx-0 hover:text-gray-500" title="Terms & Conditions">Privacy policy</a><br />
        <a href="https://www.iubenda.com/privacy-policy/66998340/cookie-policy" target="_blank" className="text-2xl mx-0 hover:text-gray-500" title="Terms & Conditions">Cookie policy</a><br />
        <a href="https://www.iubenda.com/terms-and-conditions/66998340" target="_blank" className="text-2xl mx-0 hover:text-gray-500" title="Terms & Conditions">Terms & conditions</a>
        {/*<Link to="/terms-and-conditions" className="text-2xl mx-0 hover:text-gray-500">Terms & conditions</Link>*/}
      </div>
      <div className="col-start-2 col-span-full xl:col-start-1">
        <p className="mp">© 2022 <b>nuine</b>.</p>
        <p className="mp">All rights reserved.</p>
      </div>
    </section>
  );
};

export default Footer;

{/* 
<section className="bg-gray-800">
      <div className="main-width">
        <footer aria-labelledby="footerHeading">
          <h2 id="footerHeading" className="sr-only">
            Footer
          </h2>

          <div className="pb-4 xl:grid xl:grid-cols-5 xl:gap-8">
            <div className="grid grid-cols-2 gap-8 xl:col-span-3">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h4>Support</h4>
                  <ul className="mt-4 mb-10 space-y-2">
                    <li>
                      <Link
                        to="/pricing"
                        className="text-base text-gray-300 hover:text-white"
                      >
                        Pricing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/docs"
                        className="text-base text-gray-300 hover:text-white"
                      >
                        Documentation
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <h4>Company</h4>
                  <ul className="mt-4 mb-10 space-y-2">
                    <li>
                      <Link
                        to="/about"
                        className="text-base text-gray-300 hover:text-white"
                      >
                        About
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div className="md:mt-0">
                  <h4>Legal</h4>
                  <ul className="mt-4 mb-10 space-y-2">
                    <li>
                      <Link
                        to="/legal/privacy-policy"
                        className="text-base text-gray-300 hover:text-white"
                      >
                        Privacy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/legal/terms-and-conditions"
                        className="text-base text-gray-300 hover:text-white"
                      >
                        Terms and conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/*<div className="border-t border-gray-700 pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0">
          <div>
            <h4>
              Subscribe to our newsletter
            </h4>
            <p className="mt-2 text-base text-gray-300">
              The latest news, articles, and resources, sent to your inbox
            </p>
          </div>
          <form className="mt-4 sm:flex sm:max-w-md lg:mt-0">
            <label htmlFor="emailAddress" className="sr-only">
              Email address
            </label>
            <input
              type="email"
              name="emailAddress"
              id="emailAddress"
              autoComplete="email"
              required
              className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400 sm:max-w-xs"
              placeholder="Enter your email"
            />
            <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <button
                type="submit"
                className="w-full bg-indigo-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
              >
                Subscribe
              </button>
            </div>
          </form>
  </div>
<p className="text-white">
  © 2021 <span className="font-bold">nuine</span>. All rights
  reserved.
</p>
</footer>
</div>
</section>
*/}

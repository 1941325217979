import React, { useState, useEffect } from "react";

import Header from "../components/header";
import Footer from "../components/footer";
import DocumentationSetup from "../app/documentationSetupMainWidget";

const Documentation = () => {
  const attributes = [
    {
      attribute: "brandId",
      description:
        "Your Nuine ID. You will find it under Settings once you have made an account.",
      type: "string",
      example: '"Aj830Gs76gL54"',
    },
    {
      attribute: "totalValue",
      description: "The total order value.",
      type: "integer or double",
      example: "858 or 858.17",
    },
    {
      attribute: "currency",
      description: "The currency code of the order.",
      type: "string",
      example: '"USD", "EUR", "GBP", other',
    },
    {
      attribute: "id",
      description:
        "An unique order id. It is recommended to use the order id from your ecommerce platform.",
      type: "string",
      example: '"s4CLg0nHZ8EvNCtmUEaX"',
    },
    {
      attribute: "items",
      description: "List of all the items in the order.",
      type: "array of object(s)",
      example: "-",
    },
    {
      attribute: "sku",
      description: "The item SKU or product id.",
      type: "string",
      example: '"P6466", "V-93757", "NILE-HUG-TY"',
    },
    {
      attribute: "qunatity",
      description: "The number of items of the specific SKU.",
      type: "integer",
      example: "1, 3, 17",
    },
    {
      attribute: "itemValue",
      description:
        "Price of the item. Do not multiply the quantity with the item price. Send only in the price for one single item.",
      type: "integer or double",
      example: "38, 156.37",
    },
  ];
  return (
    <>
      <Header />
      <div className="grid grid-cols-6 gap-5 mx-5 xl:mx-auto xl:w-[1078px]">
        <section className="col-span-full pt-12 pb-24">
          <div>
            <h2 className="">Setup Nuine</h2>
            <p className="text-gray-800 pt-4">
              It is easy to setup Nuine on your ecommerce store. Just follow
              these three steps or send them to your developer.
            </p>
          </div>

          <div className="flex flex-row pb-16 pt-8">
            <div className="h-8 w-8 min-w-[32px] mr-6 bg-coral-500 flex justify-center items-center font-bold text-xl text-whiteish antialiased">
              1
            </div>
            <div>
              <h3 className="">Add the main snippet</h3>
              <p className="text-gray-800 pt-4">
                Add this snippet in the{" "}
                <code className="bg-gray-100 p-1 text-sm">head</code> section of your
                code. That is between{" "}
                <code className="bg-gray-100 p-1 text-sm">head</code> and{" "}
                <code className="bg-gray-100 p-1 text-sm">/head</code>. You need only
                one global snippet per page.
              </p>
              <div className="bg-gray-100 px-8 py-4 text-sm mt-4">
                <code>
                  {`<script>`}
                  <br />
                  <span className="pl-6">
                    {`(function (w, d, s, o, f, js, fjs) {`}
                  </span>
                  <br />
                  <span className="pl-12">
                    {`w["Simple-Widget"] = o;`}

                    {` w[o] =`}

                    {` w[o] ||`}

                    {` function () {`}
                  </span>
                  <br />
                  <span className="pl-20">
                    {`(w[o].q = w[o].q || []).push(arguments);`}
                  </span>
                  <br />
                  <span className="pl-12">{`};`}</span>
                  <br />
                  <span className="pl-12">
                    {`(js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);`}
                  </span>
                  <br />
                  <span className="pl-12">
                    {`js.id = o;`}

                    {` js.src = f;`}

                    {` js.async = 1;`}

                    {`fjs.parentNode.insertBefore(js, fjs);`}
                  </span>
                  <br />
                  <span className="pl-6">
                    {`})(window, document, "script", "w1", "./widget.js");`}
                  </span>
                  <br />
                  <span className="pl-6">
                    {`w1("init", { targetElementId: "nuine-widget", brandId: BRAND_ID });`}
                  </span>
                  <br />
                  {`</script>`}
                  <br />
                </code>
              </div>
            </div>
          </div>
          <div className="flex flex-row pb-16">
            <div className="h-8 w-8 min-w-[32px] mr-6 bg-coral-500 flex justify-center items-center font-bold text-xl text-whiteish antialiased">
              2
            </div>
            <div>
              <h3 className="">Display the widget</h3>
              <p className="text-gray-800 pt-4">
                This snipped are to be placed on all pages where you want the
                widget to display:
              </p>
              <div className="bg-gray-100 px-8 py-4 text-sm mt-4">
                <code>{`<div id="nuine-widget"></div>`}</code>
              </div>
            </div>
          </div>
          <div className="flex flex-row pb-16">
            <div className="h-8 w-8 min-w-[32px] mr-6 bg-coral-500 flex justify-center items-center font-bold text-xl text-whiteish antialiased">
              3
            </div>
            <div>
              <h3 className="">(Optional) Send the order event</h3>
              <p className="text-gray-800 pt-4">
                If you want to track the effect of Nuine and the performance of
                the individual sales representatives, you need to send Nuine an
                event each time an order is placed. Add this script on your
                order confirmation page and replace the colored text with order
                data.
              </p>
              <p className="text-gray-800 pt-4">
                This snippet must be placed right below the main snippet.
              </p>
              <div className="bg-gray-100 px-8 py-4 text-sm mt-4">
                <code>
                  {`<script>`}
                  <br />
                  <span className="pl-6">{`w1("order", {`}</span>
                  <br />
                  <span className="pl-12">
                    brandId: <span className="text-indigo-800">{`BRAND_ID`}</span>,
                  </span>
                  <br />
                  <span className="pl-12">{`order: {`}</span>
                  <br />
                  <span className="pl-20">{`totalValue:`}</span>{" "}
                  <span className="text-indigo-800">{`TOTAL_ORDER_VALUE,`}</span>
                  <br />
                  <span className="pl-20">{`currency:`}</span>{" "}
                  <span className="text-indigo-800">{`CURRENCY_ISO_CODE,`}</span>
                  <br />
                  <span className="pl-20">{`id:`}</span>{" "}
                  <span className="text-indigo-800">{`ORDER_ID`}</span>
                  <br />
                  <span className="pl-20">{`items: [`}</span>
                  <br />
                  <span className="pl-28">{`{ sku: `}</span>{" "}
                  <span className="text-indigo-800">{`PRODUCT_SKU`}</span>
                  {`, quantity: `}
                  <span className="text-indigo-800">{`PRODUCT_QUANTITY`}</span>
                  {`, itemValue: `}
                  <span className="text-indigo-800">{`ITEM_VALUE`}</span>
                  {` },`}
                  <br />
                  <span className="pl-28">{`{ sku: `}</span>{" "}
                  <span className="text-indigo-800">{`PRODUCT_SKU`}</span>
                  {`, quantity: `}
                  <span className="text-indigo-800">{`PRODUCT_QUANTITY`}</span>
                  {`, itemValue: `}
                  <span className="text-indigo-800">{`ITEM_VALUE`}</span>
                  {` },`}
                  <br />
                  <span className="pl-20">{`],`}</span>
                  <br />
                  <span className="pl-12">{`},`}</span>
                  <br />
                  <span className="pl-6">{`});`}</span>
                  <br />
                  {`</script>`}
                  <br />
                </code>
              </div>

              <div className="overflow-hidden border-b border-gray-200 pt-10">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                      >
                        Attribute
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                      >
                        Example
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {attributes.map((attribute) => (
                      <tr key={attribute.attribute}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <code>{attribute.attribute}</code>
                        </td>
                        <td className="px-6 py-4 whitespace-wrap text-sm text-gray-600">
                          {attribute.description}
                        </td>
                        <td className="px-6 py-4 whitespace-wrap text-sm text-gray-600">
                          {attribute.type}
                        </td>
                        <td className="px-6 py-4 whitespace-wrap text-sm text-gray-600">
                          {attribute.example}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p className="text-gray-800 pt-8">Example snippet:</p>
              <div className="bg-gray-100 px-8 py-4 text-sm mt-4">
                <code>
                  {`<script>`}
                  <br />
                  <span className="pl-6">{`w1("order", {`}</span>
                  <br />
                  <span className="pl-12">
                    brandId: Aj839Gs76gL54,
                  </span>
                  <br />
                  <span className="pl-12">{`order: {`}</span>
                  <br />
                  <span className="pl-20">{`totalValue: 302.57,`}</span>
                  <br />
                  <span className="pl-20">{`currency: "USD",`}</span>
                  <br />
                  <span className="pl-20">{`id: "s4CLg0nHZ8EvNCtmUEaX",`}</span>
                  <br />
                  <span className="pl-20">{`items: [`}</span>
                  <br />
                  <span className="pl-28">
                    {`{ sku: "N345", quantity: 2, itemValue: 65 },`}
                  </span>
                  <br />
                  <span className="pl-28">
                    {`{ sku: "N008", quantity: 1, itemValue: 172.57 },`}
                  </span>
                  <br />
                  <span className="pl-20">{`],`}</span>
                  <br />
                  <span className="pl-12">{`},`}</span>
                  <br />
                  <span className="pl-6">{`});`}</span>
                  <br />
                  {`</script>`}
                  <br />
                </code>
              </div>
            </div>
          </div>
          <div className="pb-16">
            <h3 className="">Success</h3>
            <p className="text-gray-800 pt-4">
              Nuine should now be up and running on your ecommerce store. If
              you run into any trouble, please don't hesitate to reach out to
              use here.
            </p>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Documentation;
